@import "@danishagro/shared/src/styles/helpers/all";

.inner {
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    @include mq(sm) {
        gap: 30px;
        padding: 50px;
    }
}

.textWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.tagline {
    font-size: rem(16);
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    @include mq(xs) {
        flex-direction: row;
        gap: 0;
    }

    @include mq(sm) {
        flex-direction: row;
        gap: 0;
    }
}

.item {
    &:first-child,
    &:nth-child(2) {
        @include mq(xs) {
            width: 100%;
        }

        @include mq(sm) {
            width: 50%;
        }
    }

    &:not(&:first-child, &:nth-child(2)) {
        @include mq(xs) {
            width: 50%;
        }

        @include mq(sm) {
            width: 33.33%;
        }
    }

    @include mq(xs) {
        padding: 10px;
    }
}
