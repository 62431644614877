@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background-color: #f5f5f5;
    display: block;
    margin-top: 12px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
}

.inner {
    background-color: var(--background-color);
    margin: auto;
    padding-bottom: var(--padding-bottom);
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
    padding-top: var(--padding-top);
}

.someList {
    display: flex;
    justify-content: center;

    svg {
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;

        @include mq(xs) {
            height: 35px !important;
            width: 35px !important;
        }

        @include mq(md) {
            height: 20px !important;
            width: 20px !important;
        }
    }
}

.text {
    display: block;
    margin-bottom: 8px;
    text-align: center;
}
