@import "@danishagro/shared/src/styles/helpers/all";

.title {
    display: block;
    font-weight: 600;
}

.workPlaceWrapper {
    background-color: var(--color-grey-5);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.inner {
    display: flex;
    flex-direction: column;
}

.info {
    display: block;
}

.boldText {
    font-weight: 600;
}
