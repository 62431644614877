@import "@danishagro/shared/src/styles/helpers/all";

.author {
    display: flex;
}

.authorImageItem {
    height: 50px;
    width: 50px;
}

.authorInfo {
    align-content: center;
    display: grid;
    padding-left: 20px;
    position: relative;
}

.authorInfoName {
    color: var(--color-grey-80);
    display: block;
    font-weight: 700;
}

.authorInfoTitle {
    color: var(--color-grey-60);
    display: block;
    font-size: rem(12);
}

.authorImage {
    position: relative;
}

.authorDate {
    align-content: center;
    color: var(--color-grey-60);
    margin-left: 15px;
    padding-left: 15px;
    position: relative;

    &::before {
        background-color: var(--color-grey-20);
        content: "";
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
    }
}
