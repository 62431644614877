@import "@danishagro/shared/src/styles/helpers/all";

.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.link {
    @include border-radius(md);
    @include box-shadow(1);
    background: var(--color-white);
    color: var(--color-grey-80);
    display: flex;
    gap: 15px;
    padding: 15px;
    text-decoration: none;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.image {
    flex: 0 0 auto;
    height: 72px;
    width: 72px;
}

.headline {
    font-size: rem(16);
    font-weight: 600;
}

.text {
    @include line-clamp(1);
    flex: 1;
}

// Tags

.tags {
    display: flex;
    gap: 10px;
}

.tag {
    background-color: var(--color-primary-4);
    border-radius: 30px;
    color: var(--color-accent);
    display: block;
    font-size: rem(12);
    padding: 1px 10px;
    user-select: none;
}
