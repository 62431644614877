.wrapper {
    overflow: hidden;
    position: relative;
    user-select: none;
    width: auto;
}

.image {
    height: 100%;
    left: 50%;
    object-fit: var(--object-fit, cover);
    object-position: center center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
