.wrapper {
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-height: 100vh;
}

.dynamicPage {
    background-color: var(--color-grey-5);
    flex-grow: 1; /* Allows DynamicPage to fill available space */
}
