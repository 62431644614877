@import "@danishagro/shared/src/styles/helpers/all";

.videoInner {
    aspect-ratio: 16 / 9;
    width: 100%;

    iframe {
        width: 100%;
    }
}

.image {
    height: 100%;
    width: 100%;
}

// Intro / Outro
.introWrapper {
    aspect-ratio: 16 / 9;
    cursor: pointer;
    flex: 1;
    position: relative;
    z-index: 1;
}

.video {
    height: 100%;
    object-fit: cover;
    position: relative;
    width: 100%;
    z-index: 1;
}

.caption {
    background-color: var(--color-grey-10);
    filter: brightness(0.9);
    padding: rem(5) rem(10);
}

// Intro play icon

.playIconContainer {
    align-items: center;
    background-color: var(--color-grey-5);
    border: none;
    border-radius: 7px;
    color: var(--color-grey-60);
    display: flex;
    height: 70px;
    justify-content: center;
    left: 50%;
    padding: 15px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    z-index: 2;
}

.iconSize {
    height: 100%;
    width: 100%;
}

.consentPlaceholderWrapper {
    align-items: center;
    background-color: #ededed;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .consentPlaceholderBox {
        text-align: center;

        @include mq(md) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    h2 {
        margin: auto;
        padding: 0 20px;
        text-align: center;

        @include mq(xs-max) {
            font-size: 16px;
        }
    }

    .iconSize {
        height: 80px;
        margin-bottom: 30px;
        width: 80px;

        @include mq(xs-max) {
            display: none;
        }
    }

    .consentPlaceholderText {
        padding: 0 20px;

        > div {
            @include mq(xs-max) {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}

.smallContent {
    .iconSize {
        display: none;
    }
}
