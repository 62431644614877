@import "@danishagro/shared/src/styles/helpers/all";

.imageWrapper {
    position: relative;
    width: 100%;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include mq(xs) {
        flex-direction: row;
    }

    @include mq(sm) {
        gap: 50px;
    }
}

.textWrapper {
    color: var(--color-grey-60);
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    @include mq(sm) {
        flex: 3 1;
    }
}

.introductory {
    margin-bottom: 25px;
}

.addressWrapper {
    display: flex;
    flex-direction: column;
    font-size: rem(16);
    gap: 15px;

    span {
        display: flex;
        gap: 10px;
    }
}

.icon {
    width: 16px;
}

.image {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 200px;
    width: 100%;

    @include mq(sm) {
        flex: 1;
    }
}

.button {
    margin-top: 30px;
}
