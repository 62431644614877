@import "@danishagro/shared/src/styles/helpers/all";

.backgroundPrimary {
    // General
    --background-color: var(--color-primary-1);
    --text-color: var(--color-white);

    // Hero
    --hero-text-color: var(--color-white);

    // DA_Accordion
    --accordion-label-color: var(--color-white);
    --accordion-icon-color: var(--color-white);

    // DA_Quote
    --quote-icon-color: var(--color-white);

    //DA_Text
    --link-color: var(--color-white);
    --link-hover-color: var(--color-grey-10);

    // DA_Tab
    --tab-active-line-color: var(--color-white);
    --tab-active-text-color: var(--color-white);
    --tab-line-color: var(--color-white);
    --tab-text-color: var(--color-white);
}

.backgroundAccent {
    // General
    --background-color: var(--color-accent);
    --text-color: var(--color-white);

    // Hero
    --hero-text-color: var(--color-white);

    // DA_Accordion
    --accordion-label-color: var(--color-white);
    --accordion-icon-color: var(--color-white);

    // DA_Quote
    --quote-icon-color: var(--color-white);

    //DA_Text
    --icon-color: var(--color-white);
    --link-icon-hover-color: var(--color-grey-10);
    --link-color: var(--color-white);
    --link-hover-color: var(--color-grey-10);

    // DA_Tab
    --tab-active-line-color: var(--color-white);
    --tab-active-text-color: var(--color-white);
    --tab-line-color: var(--color-white);
    --tab-text-color: var(--color-white);
}

.backgroundGrey {
    // General
    --background-color: var(--color-grey-10-overwrite, var(--color-grey-10));
    --text-color: var(--color-grey-80);

    // Hero
    --hero-text-color: var(--color-grey-80);

    // DA_Accordion
    --accordion-label-color: var(--color-grey-90);
    --accordion-icon-color: currentcolor;

    // DA_Quote
    --quote-icon-color: var(--color-accent);

    // DA_Tab
    --tab-active-line-color: var(--color-primary-1);
    --tab-active-text-color: var(--color-primary-1);
    --tab-line-color: var(--color-grey-20);
    --tab-text-color: var(--color-grey-80);
}

.backgroundWhite {
    // General
    --background-color: var(--color-white);
    --text-color: var(--color-grey-80);

    // Hero
    --hero-text-color: var(--color-grey-80);

    // DA_Accordion
    --accordion-label-color: var(--color-grey-90);
    --accordion-icon-color: currentcolor;

    // DA_Quote
    --quote-icon-color: var(--color-accent);

    //DA_Input {
    --input-padding-left: #{rem(37)};

    // DA_Tab
    --tab-active-line-color: var(--color-primary-1);
    --tab-active-text-color: var(--color-primary-1);
    --tab-line-color: var(--color-grey-20);
    --tab-text-color: var(--color-grey-80);
}
