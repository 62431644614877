@import "@danishagro/shared/src/styles/helpers/all";

.buttons {
    align-items: center;
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    white-space: var(--textImageBanner-button-text-white-space, "nowrap");

    &:empty {
        display: none;
    }
}

.inlineLinkTheme[class] {
    --icon-color: var(--color-white);
    color: var(--color-white);

    &:hover {
        --icon-color: var(--color-white);
        color: var(--color-white);
        filter: brightness(0.9);
    }
}
