@import "@danishagro/shared/src/styles/helpers/all";

.blockPadding {
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-left: 20px;
    --padding-right: 20px;

    @include mq(sm) {
        --padding-top: 50px;
        --padding-bottom: 50px;
        --padding-left: 50px;
        --padding-right: 50px;
    }

    // Compact mode

    &.blockPaddingCompact {
        --padding-top: 10px;
        --padding-bottom: 10px;
        --padding-left: 20px;
        --padding-right: 20px;

        @include mq(sm) {
            --padding-top: 15px;
            --padding-bottom: 15px;
            --padding-left: 15px;
            --padding-right: 15px;
        }
    }
}
