@import "@danishagro/shared/src/styles/helpers/all";

.inner {
    background-color: var(--color-white);
    padding: 20px;

    @include mq(sm) {
        padding: 50px;
    }
}

.resetButton {
    border: none;
    width: max-content;

    &::before {
        border: none;
    }

    span {
        color: var(--color-primary-1);
        text-decoration: underline;
    }

    &:hover {
        span {
            color: var(--color-alert);
        }
    }
}

.contentWrapper {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 10px;

    @include mq(lg) {
        flex-direction: inherit;
        gap: 30px;
        padding-top: 0;
    }
}

.jobListWrapper {
    display: grid;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    @include mq(xl) {
        flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
    }
}

.removeChip {
    margin-bottom: 5px;
    margin-right: 10px;
    width: max-content;

    &:last-of-type {
        margin-right: 5px;
    }
}

.filterOptionsWrapper {
    display: block;
}

.filterSelectedLabel {
    font-size: rem(14);
    font-weight: bold;
    padding-right: 5px;
}

.select {
    margin-top: 5px;
}

.selectLabel {
    font-weight: 600;
}

.selectWrapper {
    background-color: var(--color-white);
    display: grid;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    grid-auto-flow: column;
    margin: auto;
    margin-top: 0 auto;
    padding: 20px 0;

    @include mq(xs-max) {
        grid-auto-flow: inherit;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }

    @include mq(sm) {
        flex-direction: row;
        grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.noPositionsMessage {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 50px;
    text-align: center;
    width: 100%;
}

.noPositionsIcon {
    align-items: center;
    background-color: var(--color-grey-10);
    border-radius: 100%;
    display: flex;
    font-size: rem(30);
    height: rem(80);
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
    padding: rem(15);
    text-align: center;
    width: rem(80);

    svg {
        height: rem(50);
        width: rem(50);
    }
}

.subTextIntro {
    div {
        gap: 5px;
    }
}

.linkToAll {
    display: flex;
    margin: auto;
    margin-top: 10px;
    width: fit-content;
}

.spinnerWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 50px;
    text-align: center;
    width: 100%;
}

.spinner {
    height: 100px;
    width: 100px;
}

.buttonWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
}
