@import "@danishagro/shared/src/styles/helpers/all";

.imageWrapper {
    position: relative;
    width: 100%;
}

// Tags

.tags {
    display: flex;
    gap: 10px;
}

.tag {
    --tag-color-red: #fecad3;
    --tag-color-blue: #d0deeb;
    --tag-text-color-blue: #103f6d;
    background-color: var(--color-primary-4);
    border-radius: 30px;
    color: var(--color-accent);
    display: block;
    font-size: rem(12);
    left: 10px;
    padding: 2px 10px;
    position: relative;
    top: 10px;
    user-select: none;
}

//Tag styling
.tagNews {
    background-color: var(--color-primary-4);
    color: var(--color-accent);
}

.tagPress {
    background-color: var(--tag-color-red);
    color: var(--color-alert);
}

.tagPartnership {
    background-color: var(--tag-color-blue);
    color: var(--tag-text-color-blue);
}

.inner {
    background: var(--color-white);
    border-radius: 5px;
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.image {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 200px;
    width: 100%;
}

.textWrapper {
    color: var(--color-grey-60);
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    padding: 16px;
}

.title {
    display: block;
}

.text {
    @include line-clamp(4);
    flex: 1;
}
