@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;

    @include mq(md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include mq(lg) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    width: 100%;

    @include mq(md) {
        flex-direction: row;
    }
}

.main {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    flex: 3;
    overflow: hidden;
    padding: 15px;
    width: 100%;

    @include mq(sm) {
        padding: 50px;
    }
}

// Make all blocks with a background-color have rounded corners
.content {
    & > * {
        @include border-radius(md);
        overflow: hidden;
    }

    & > * + * {
        margin-top: 30px;
    }
}

.aside {
    flex: 1;
    width: 100%;

    &:empty {
        display: none;
    }
}

.toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
    gap: 10px;
    
    @include mq(xs) {
        align-items: center;
        flex-direction: row;
        gap: 0;
    }

    &:has(> *:only-child) {
        justify-content: flex-end;
    }
}

.introductory {
    color: var(--color-grey-60);
    display: block;
    font-size: rem(18);
    line-height: rem(25);
    margin-bottom: 30px;
    margin-top: -10px;
}

// Tags

.tags {
    display: flex;
    gap: 10px;
}

.tag {
    background-color: var(--color-primary-4);
    border-radius: 30px;
    color: var(--color-accent);
    display: block;
    font-size: rem(12);
    padding: 5px 10px;
    user-select: none;
}
