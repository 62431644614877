@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    margin-bottom: 20px;
    padding-bottom: 40px;

    @include mq(sm) {
        margin-bottom: 0;
    }
}
