@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;

    @include mq(md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include mq(lg) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
    width: 100%;

    @include mq(md) {
        flex-direction: row;
    }
}

.main {
    background-color: var(--color-white);
    flex: 2;
    overflow: hidden;
    padding: 15px;
    width: 100%;

    @include mq(md) {
        padding: 50px;
    }
}

.inner {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;

    @include mq(md) {
        flex-direction: row;
        gap: 50px;
    }
}

.asideWrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    @include mq(md) {
        min-width: 400px;
    }
}

.jobTitle {
    color: var(--color-white);
    font-weight: 600;
}

.jobInfoWrapper {
    background-color: var(--color-primary-1);
    color: var(--color-white);
    padding: 20px;
}

.buttonWrapper {
    margin-top: 20px;
}

//Line height for dynamic job details content for Headline and subheadlines
.textWrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
    }
}

// Make all blocks with a background-color have rounded corners
.content {
    & > * {
        @include border-radius(md);
        overflow: hidden;
    }

    & > * + * {
        margin-top: 30px;
    }
}

//hero
.hero {
    --hero-no-filter: 0;
}

.mainContactPerson {
    margin: 20px 0;

    @include mq(sm) {
        margin: 50px 0;
    }

    a {
        color: var(--color-primary-1);
    }
}

.asideContactPerson {
    margin: 20px 0;

    a,
    h3 {
        color: var(--color-white);
    }
}

//TODO: use for logo later
// .logoWrapper {
//     margin-top: 10px;
// }

.introductory {
    color: var(--color-grey-60);
    display: block;
    font-size: rem(18);
    line-height: rem(25);
    margin-bottom: 30px;
    margin-top: -10px;
}

.buttonApply {
    color: var(--color-primary-1);
}

.goBackBtn {
    margin-top: 20px;
    position: relative;
}
