@import "@danishagro/shared/src/styles/helpers/all";

.list {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px;

    @include mq(sm) {
        padding: 50px;
    }
}
