@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    --height: 100%;
    display: block;
    width: 100%;
}

.container {
    height: 100%;
}

.inner {
    align-items: stretch;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    @include mq(sm) {
        flex-direction: row;
        margin-bottom: 0;
        min-height: var(--hero-inner-min-height, 350px);
    }

    // Settings: Image Cover

    .layoutCoverWhiteFilter &,
    .layoutCoverBlackFilter & {
        padding-bottom: calc(var(--padding-left) * 4);
        padding-left: calc(var(--padding-left) * 2);
        padding-right: calc(var(--padding-right) * 2);
        padding-top: calc(var(--padding-right) * 4);
        position: relative;

        &::before {
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        @include mq(sm) {
            min-height: 650px;
        }
    }

    .layoutCoverWhiteFilter & {
        &::before {
            background: var(
                --hero-no-filter,
                linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0.65) 0%,
                    rgba(255, 255, 255, 0.45) 70%,
                    rgba(255, 255, 255, 0) 100%
                )
            );

            @include mq(sm) {
                background: var(
                    --hero-no-filter,
                    linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 0.75) 0%,
                        rgba(255, 255, 255, 0.65) 50%,
                        rgba(255, 255, 255, 0) 80%
                    )
                );
            }

            @include mq(md) {
                background: var(
                    --hero-no-filter,
                    linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 0.75) 0%,
                        rgba(255, 255, 255, 0.65) 50%,
                        rgba(255, 255, 255, 0) 70%
                    )
                );
            }

            @include mq(lg) {
                background: var(
                    --hero-no-filter,
                    linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 0.75) 0%,
                        rgba(255, 255, 255, 0.65) 40%,
                        rgba(255, 255, 255, 0) 55%
                    )
                );
            }
        }
    }

    .layoutCoverBlackFilter & {
        &::before {
            background: var(
                --hero-no-filter,
                linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.65) 0%,
                    rgba(0, 0, 0, 0.45) 70%,
                    rgba(0, 0, 0, 0) 100%
                )
            );

            @include mq(sm) {
                background: var(
                    --hero-no-filter,
                    linear-gradient(90deg, rgba(0, 0, 0, 0.65) 5%, rgba(0, 0, 0, 0) 50%)
                );
            }
        }
    }

    // Settings: Image Left/Right

    .heroImageLeft &,
    .heroImageRight & {
        gap: 20px;

        @include mq(sm) {
            gap: 50px;
            min-height: 500px;
        }
    }

    .heroImageRight & {
        @include mq(sm) {
            flex-direction: row-reverse;
        }
    }

    // Settings: No Image

    .heroText & {
        padding-bottom: calc(var(--padding-left) * 2);
        padding-left: calc(var(--padding-left) * 2);
        padding-right: calc(var(--padding-right) * 2);
        padding-top: calc(var(--padding-right) * 2);

        //Thin hero
        &.thinHero {
            min-height: auto;
            padding-bottom: 66px;
            padding-left: calc(var(--padding-left) * 1);
            padding-right: calc(var(--padding-right) * 1);
            padding-top: 66px;
        }
    }
}

// VIDEO

.videoWrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 0;

    .heroImageLeft &,
    .heroImageRight & {
        flex: 1;
        height: auto;
        overflow: hidden;
        position: relative;
        transform: none;
    }
}

.video {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

// LOGO

.logo {
    width: 200px;
}

// IMAGE

.imageWrapper {
    width: 100%;

    @include mq(sm) {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    // Settings

    .layoutCoverWhiteFilter &,
    .layoutCoverBlackFilter & {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    .image {
        height: 100%;
        width: 100%;
    }

    .video {
        height: 100%;
        width: 100%;
    }
}

.imageWrapperFull {
    @include mq(sm) {
        flex-basis: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

// CONTENT

.contentWrapper {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    z-index: 2;

    @include mq(sm) {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    // Settings: Image Left/Right

    .heroImageLeft &,
    .heroImageRight & {
        padding-bottom: var(--padding-bottom);
        padding-top: var(--padding-top);
    }
}

.content {
    --title-color: var(--hero-text-color, var(--color-grey-80));
    color: var(--hero-text-color, var(--color-grey-80));
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 60ch;

    .thinHero & {
        max-width: 100%;
    }

    .heroImageRight & {
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);

        @include mq(sm) {
            padding-right: 0;
        }
    }

    .heroImageLeft & {
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);

        @include mq(sm) {
            padding-left: 0;
        }
    }

    .heroImageLeft &,
    .heroImageRight & {
        max-width: 100ch;
    }

    // Settings

    .layoutCoverBlackFilter & {
        --title-color: var(--color-white);
        color: var(--color-white);
    }
}

.tagline {
    font-size: rem(16);
}
