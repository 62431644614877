@use "sass:math";

/* stylelint-disable */
@import "../../../../../node_modules/normalize-scss/sass/_normalize.scss";
@import "../../../../../node_modules/react-spring-bottom-sheet/dist/style.css";

/* stylelint-enable */
@import "../helpers/all";
@import "./colors";
@import "./themes";
@import "./fonts";
@import "./date-picker-overrides";
@import "./toaster";

html {
    box-sizing: border-box;
    color: var(--color-grey-80);
    height: 100%;
    scroll-behavior: smooth;

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
}

body {
    // BEWARE! Using "height: 100%" in body will break the scroll lock used when opening the mobile menu.
    background-color: var(--color-grey-10);
    font-family: $font-family;
    font-size: rem(14);
    margin: 0;
    min-height: 100%;
    overflow-x: hidden;

    .scroll-locked & {
        overflow: hidden;
    }

    // Use lighter background for storybook
    &.sb-show-main {
        background-color: var(--color-grey-5);

        &.sb-main-padded {
            padding: rem(16);
        }
    }
}

/* stylelint-disable */
#__root {
    height: 100%;
}
/* stylelint-enable */

.page-container {
    display: flex;
    flex-direction: column;
}

main {
    background-color: var(--color-grey-5);
    flex: 1;
    min-height: calc(100vh - var(--header-height, 167px) - var(--footer-height, 100px));
    padding-bottom: 35px;
    scroll-margin-top: var(--header-height, 167px);

    .page-is-loading & {
        opacity: 0.333;
        transition: opacity 0.2s ease-out;
    }

    .sb-show-main & {
        background-color: transparent;
    }

    @media print {
        background-color: var(--color-white);
    }
}

.frontpage {
    @include mq(md) {
        padding-top: 15px;
    }
}

.frontpage-content {
    display: flex;
    flex-direction: column;
}

// Don't disable scroll for people with Ghostery or another ad and cookie consent blocker
.noScroll {
    &,
    & body {
        overflow-y: auto !important;
    }
}

img {
    max-width: 100%;
}

iframe {
    border: 0;
}

.visually-hidden {
    @include visuallyhidden;
}

.mobile-only {
    @media (min-width: 720px) {
        display: none;
    }
}

.mobile-hide {
    display: none;

    @media (min-width: 720px) {
        display: initial;
    }
}

.mobile-tablet-only {
    @media (min-width: 992px) {
        display: none;
    }
}

.mobile-tablet-hide {
    display: none;

    @media (min-width: 992px) {
        display: initial;
    }
}

.break-all {
    word-break: break-all;
}

.unknown-link {
    pointer-events: none !important;
    text-decoration: line-through !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.video-container {
    background: black;
    height: 0;
    padding: math.percentage(math.div(9, 16)) 0 0 0;
    position: relative;

    > * {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.modal-container {
    inset: 40px;
    position: fixed;
    z-index: 999;
}

.modal-overlay {
    background: rgba(#000, 0.25);
    inset: -40px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.25s ease-out;
    z-index: 1;

    &--show {
        opacity: 1;
    }
}

%modal-content {
    left: 0;
    margin: auto;
    max-height: 90vh;
    max-width: 1100px;
    outline: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.modal-content {
    @extend %modal-content;
}

/* Includes math magic to keep the aspect ratio 16:9 */
.modal-video-content {
    @extend %modal-content;
    height: calc(56.25vw - 80px);
    margin: auto;
    max-height: calc(100vh - 80px);
    max-width: calc(177.78vh - 144px);
    width: calc(100vw - 144px);
}

.modal-content-inside {
    @include border-radius(md);
    @include box-shadow(3);
    background: var(--color-white);
    outline: none;
    overflow: hidden;
    position: relative;

    .modal-content & {
        max-height: 90vh;
        overflow-y: auto;
    }

    .modal-video-content & {
        background: black;
    }
}

.bs-wrapper {
    position: relative;
    z-index: 901;

    [data-rsbs-header] {
        box-shadow: none;
    }

    [data-rsbs-content] {
        min-height: 100%;
        padding-bottom: var(--keyboard-height);
    }

    [data-rsbs-scroll] {
        overscroll-behavior: auto;
    }

    [data-rsbs-footer] {
        min-height: calc($toolbar-height + env(safe-area-inset-bottom)); // Toolbar + safe-area
    }

    &.bs-hide-handle [data-rsbs-header] {
        display: none;
    }
}

.quill .ql-container {
    font-size: rem(14);

    .ql-editor {
        max-height: rem(200);
        overflow: auto;

        a {
            @include link-style();
        }

        p,
        ol,
        ul,
        hr,
        pre,
        blockquote,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 1em 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        ol,
        ul {
            padding: 0;
        }

        hr {
            background: transparent;
            border: 0;
            border-bottom: 1px solid var(--color-grey-20);
            height: 0;
            margin: 20px 0;
            padding: 0;
        }
    }
}

html .react-loading-skeleton {
    @include border-radius(sm);
}
