@import "@danishagro/shared/src/styles/helpers/all";

.jobListCard {
    border-bottom: 1px solid var(--color-grey-10);
    color: var(--color-grey-80);
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    padding: 20px 0;
    text-decoration: none;
    width: 100%;

    @include mq(sm) {
        flex-direction: row;
        margin-bottom: 0;
    }
}

.title {
    font-size: 24px;
    font-weight: 600;
    hyphens: auto;
}

.teaserWrapper {
    flex: 2;
}

.teaserText {
    font-size: rem(16);
    position: relative;
}

.locationText {
    --line-height: auto;
}

.readMoreLink {
    display: inline;
    font-size: rem(14);
    min-width: 100px;
    padding-left: 15px;
    position: relative;

    &::before {
        content: "...";
        left: 0;
        position: absolute;
        top: 0;
    }
}

.imageWrapper {
    width: 100%;

    @include mq(md) {
        flex: 0 0 auto;
        max-width: 300px;
    }
}

.jobInfoList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    list-style: none;
    margin: 0;
    margin-top: 20px;
    padding: 0;

    @include mq(sm) {
        gap: 2px;
    }
}

//Job info
.jobInfoItem {
    display: flex;
    flex-direction: column;

    @include mq(sm) {
        align-items: center;
        flex-direction: row;
        gap: 2px;
    }
}

.positionCategory,
.departmentName {
    font-size: rem(16);
    min-height: 22px;
}

.jobInfoLabel {
    font-size: rem(16);
    font-weight: bold;
}
