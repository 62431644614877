@import "@danishagro/shared/src/styles/helpers/all";

.extraInfoWrapper {
    display: block;

    .extraBlock {
        display: flex;
        // justify-content: space-between;

        &:first-child {
            margin-bottom: 5px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .contactTitle {
        font-weight: 700;
        max-width: 130px;
        min-width: 130px;
    }

    .title {
        display: block;
        font-weight: var(--jobInfo-title-weight, 700);
        max-width: 130px;
        min-width: 130px;
    }

    .info {
        display: block;
    }

    .flexWrapper {
        --jobInfo-align-items: baseline;
        --jobInfo-gap: 0;
        --jobInfo-title-weight: normal;
        --jobInfo-grid-columns: 1fr;
    }

    .extraInfoContactBox {
        display: grid;
        gap: var(--jobInfo-gap, 15px);
        grid-template-columns: var(--jobInfo-grid-columns, 75px 1fr);
        margin-top: 20px;

        @include mq(sm) {
            align-items: var(--jobInfo-align-items, center);
            display: flex;
        }

        .extraInfoContactBoxLeft {
            display: block;

            @include mq(sm) {
                flex: 1;
                max-width: 60px;
            }
        }

        .extraInfoContactBoxRight {
            display: block;

            @include mq(sm) {
                flex: 4;
            }

            .jobPosition {
                display: block;
                margin-bottom: 2px;
                margin-top: 2px;
            }

            .extraInfoContactBoxRightReach {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
            }
        }
    }
}
