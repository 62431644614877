@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background-color: var(--color-grey-5);
    flex: 1;
    height: 100%;
    min-width: 300px;
    padding: 20px;

    @include mq(md) {
        top: 180px;
    }

    @include mq(xl) {
        position: sticky;
    }
}
