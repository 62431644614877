@import "@danishagro/shared/src/styles/helpers/all";

.inner {
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    @include mq(sm) {
        gap: 30px;
        padding: 50px;
    }
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    @include mq(xs) {
        flex-direction: row;
        gap: 0;
    }

    @include mq(sm) {
        flex-direction: row;
        gap: 0;
    }
}

.item {
    height: 100%;
}

.groupeList {
    display: grid;
    gap: 20px;
}

.jobList {
    margin-bottom: 30px;
}

.textWrapper {
    margin: auto;
    width: 100%;

    @include mq(sm) {
        width: 75%;
    }
}

.twoLines {
    grid-template-columns: 1fr;

    @include mq(xs) {
        grid-template-columns: 1fr 1fr;
    }
}

.threeLines {
    grid-template-columns: 1fr;

    @include mq(xs) {
        grid-template-columns: 1fr 1fr;
    }

    @include mq(lg) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.fourLines {
    grid-template-columns: 1fr;

    @include mq(xs) {
        grid-template-columns: 1fr 1fr;
    }

    @include mq(md) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq(lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
