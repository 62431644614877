@import "@danishagro/shared/src/styles/helpers/all";

.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.link {
    @include border-radius(md);
    @include box-shadow(1);
    background: var(--color-white);
    color: var(--color-grey-80);
    display: flex;
    gap: 15px;
    padding: 15px;
    text-decoration: none;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.image {
    flex: 0 0 auto;
    height: 70px;
    width: 70px;
}

.headline {
    font-size: rem(16);
    font-weight: 600;
}
